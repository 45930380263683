import { makeStyles } from '@material-ui/core';
import { useState, useContext, useEffect } from 'react';
import { useTranslate } from '../../../../hooks/useTranslate';
import { ClientInformationInitialState } from '..';
import Text from '../../../shared/Typography';
import SubmitButtonContainer from '../SubmitButtonContainer';
import { GetFieldPropsFunction, SetValueFunction } from '../../../../hooks/useForm';
import ClientSelect from './ClientSelect';
import { PartnerClient } from '../../../../types';
import CreateClientForm from '../../../shared/CreateClientForm';
import { SnackbarContext } from '../../../../contexts/snackbar-context';
import ButtonText from '../../../shared/ButtonText';
import AlertContainer from '../../../shared/AlertContainer';

interface ClientInformationProps {
  setRefetchConsentForm: (boolean: boolean) => void;
  getFieldPropsFunction: GetFieldPropsFunction<ClientInformationInitialState>;
  allowNext: boolean;
  handleNext: () => void;
  setClientInformationValuesFunction: SetValueFunction<{ email: string; user: PartnerClient }>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    margin: '0 1rem'
  },
  searchInput: {
    padding: '13px 31px'
  },
  alertContainer: {
    height: '24px',
    marginTop: '40px'
  }
}));

const ClientInformation = ({
  setRefetchConsentForm,
  getFieldPropsFunction,
  handleNext,
  setClientInformationValuesFunction
}: ClientInformationProps) => {
  const classes = useStyles();
  const { addNotification } = useContext(SnackbarContext);
  const { t } = useTranslate('createNewRequest');
  const [patientDetails, setPatientDetails] = useState<PartnerClient>();
  const [showClientManualForm, setShowClientManualForm] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSubmitSuccess = (user?: PartnerClient, hasUserUpdates?: boolean) => {
    if (!user) {
      setErrorMessage(t('physicians.clientInformationStep.errors.noClientInformation'));
      return;
    }
    setClientInformationValuesFunction('email', user.email);
    setClientInformationValuesFunction('user', user);

    if (hasUserUpdates) {
      if (isEditMode && user) {
        addNotification(t('physicians.clientInformationStep.editSuccess'), 'success');
      } else {
        addNotification(t('physicians.clientInformationStep.createSuccess'), 'success');
      }
      setRefetchConsentForm(true);
    }

    handleNext();
    setIsEditMode(false);
  };

  const openManualClientForm = () => {
    setPatientDetails(undefined);
    setShowClientManualForm(true);
    setIsEditMode(false);
  };

  const selectUserClient = (user: PartnerClient) => {
    !showClientManualForm && setShowClientManualForm(true);
    setIsEditMode(true);
    setPatientDetails(user);
    setRefetchConsentForm(true);
  };

  useEffect(() => {
    const patientFormDetails = getFieldPropsFunction('user').value;

    if (patientFormDetails?.id && patientFormDetails?.email) {
      setIsEditMode(true);
      setShowClientManualForm(true);
      setPatientDetails(patientFormDetails);
    }
    // eslint-disable-next-line
  }, []);

  const renderFormContent = () => {
    return (
      <>
        <ClientSelect placeholder={t('physicians.clientInformationStep.seachInputLabel')} onSelect={selectUserClient} />
        <ButtonText
          isPrimary
          style={{ margin: '1.5rem 1rem 0 1rem' }}
          label={t('physicians.clientInformationStep.manualButtonLabel')}
          onClick={openManualClientForm}
        />
        {showClientManualForm ? (
          <CreateClientForm
            onSubmitSuccess={onSubmitSuccess}
            patientDetails={patientDetails}
            editMode={isEditMode}
            submitText={
              isEditMode
                ? t('physicians.clientInformationStep.continueButtonText')
                : t('physicians.clientInformationStep.createClientButtonText')
            }
          />
        ) : (
          <>
            <AlertContainer className={classes.alertContainer}>
              <Text paragraph align='center' className='is-error'>
                {!!errorMessage && errorMessage}
              </Text>
            </AlertContainer>
            <SubmitButtonContainer
              style={{ padding: '14px 76px', marginTop: '20px' }}
              onClick={() => onSubmitSuccess()}
              label={t('physicians.clientInformationStep.continueButtonText')}
            />
          </>
        )}
      </>
    );
  };

  const getHeading = () => {
    return t('physicians.clientInformationStep.heading');
  };

  const getDescription = () => {
    return t('physicians.clientInformationStep.description');
  };

  return (
    <div className={classes.root}>
      <div className='flex center-horizontal'>
        <div className={classes.container}>
          <div>
            <Text h2 fontWeight={400} style={{ marginBottom: '20px' }}>
              {getHeading()}
            </Text>
            <Text paragraph>{getDescription()}</Text>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '35px' }}>{renderFormContent()}</div>
    </div>
  );
};

export default ClientInformation;
