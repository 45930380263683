import { Collapse, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// User Auth Info
import { AuthContext } from '../../../contexts/auth-context';

// Helper Hooks
import { useRoutes } from '../../../hooks/useRoutes';
import { useTranslate } from '../../../hooks/useTranslate';

// Request Hooks
import { useFetchBusinessUploadStatus } from '../../../request-hooks/shared/useSharedRequest';
import { useFetchPartnerIncomingRequestsMeta } from '../../../request-hooks/partners/usePartnerRequests';

// Permisions
import Can, { useCheckPermission } from '../../shared/Can';
import { USER_ACTIONS } from '../../../utils/rbac';

// Dot Components
import InsufficientFundMessage from '../../shared/InsufficientFundMessage';
import ProcessingUploadsMessageContainer from '../../shared/ProcessingUploadsMessageContainer';
import Tab from '../../shared/Tab';
import TabsList from '../../shared/TabsList';
import Tabs from '../../shared/Tabs';
import FirstSignInModal from './FirstSignInModal';
import { FIRST_SIGN_IN_KEY } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: 'fit-content',
    margin: 0,
    marginTop: '5rem',
    display: 'flex',
    gap: '4rem',
    height: '5.5rem',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tab: {
    fontSize: '2.4rem',
    width: 'fit-content',
    '&.is-active': {
      color: theme.palette.primary.main
    }
  }
}));

interface RequestsTabListViewProps {
  active?: 'myRequests' | 'allRequests' | 'incomingRequests';
  children: React.ReactNode;
}

const RequestsTabListView = ({ children, active }: RequestsTabListViewProps) => {
  const [view, setView] = useState(active);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { t, ready } = useTranslate('clientAndRequestTabs');
  const { routesTFunction } = useRoutes();
  const { hasPaymentPlan, paymentPlan, isOwner, isManager } = useContext(AuthContext);

  const { businessUploadStatus } = useFetchBusinessUploadStatus({
    shouldFetch:
      useCheckPermission([USER_ACTIONS.FETCH.BUSINESS_UPLOADS]) && location.state?.shouldFetchBusinessUploadStatus
  });
  const { incomingRequestsMeta } = useFetchPartnerIncomingRequestsMeta({
    shouldFetch: useCheckPermission([USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS]),
    shouldClearCache: true
  });

  const shouldShowProcessingContainer = useMemo(
    () => !!businessUploadStatus?.business_uploads.length,
    [businessUploadStatus]
  );

  const shouldShowInsufficientFunds = useMemo(() => {
    if (hasPaymentPlan && paymentPlan?.disabled) {
      return true;
    }

    return false;
  }, [hasPaymentPlan, paymentPlan]);

  useEffect(() => {
    if (location.pathname === routesTFunction('routerPaths./requests')) {
      setView('myRequests');
    } else if (location.pathname === routesTFunction('routerPaths./requests/all')) {
      setView('allRequests');
    } else if (location.pathname === routesTFunction('routerPaths./requests/incoming')) {
      setView('incomingRequests');
    }
  }, [location, routesTFunction]);

  useEffect(() => {
    const isFirstSignIn = localStorage.getItem(FIRST_SIGN_IN_KEY);

    if (isFirstSignIn && isFirstSignIn === 'true') {
      setIsWelcomeModalOpen(true);
    }
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Collapse in={shouldShowProcessingContainer}>
        <ProcessingUploadsMessageContainer />
      </Collapse>
      <FirstSignInModal
        isOpen={isWelcomeModalOpen}
        onClose={() => setIsWelcomeModalOpen(false)}
        getStartedCallback={() => {}}
      />
      <Collapse in={shouldShowInsufficientFunds}>
        <InsufficientFundMessage />
      </Collapse>
      <Tabs
        value={view}
        onValueChange={(value) => {
          if (value === 'myRequests') {
            setView('myRequests');
            history.push(routesTFunction('redirectPaths./requests'));
          } else if (value === 'allRequests') {
            setView('allRequests');
            history.push(routesTFunction('redirectPaths./requests/all'));
          } else if (value === 'incomingRequests') {
            setView('incomingRequests');
            history.push(routesTFunction('redirectPaths./requests/incoming'));
          }
        }}
      >
        <TabsList className={classes.tabContainer}>
          <Tab value='myRequests' className={classes.tab} label={t('myRequestsView')} />
          {(isManager || isOwner) && <Tab value='allRequests' className={classes.tab} label={t('allRequestsView')} />}
          <Can perform={USER_ACTIONS.VIEW.REQUEST_DASHBOARD_PAGE_TABS.INCOMING_REQUESTS}>
            <Tab
              value='incomingRequests'
              className={classes.tab}
              label={`${t('incomingRequests')} ${incomingRequestsMeta?.count ? `(${incomingRequestsMeta.count})` : ''}`}
            />
          </Can>
        </TabsList>
        {children}
      </Tabs>
    </>
  );
};

export default RequestsTabListView;
