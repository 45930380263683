import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Material UI
import { makeStyles } from '@material-ui/core/';

// Form Hooks
import { useForm, ValidationErrors } from '../../../hooks/useForm';

// Components
import Input from '.';
import Text from '../Typography';

const useStyles = makeStyles((theme) => ({
  dateInput: {
    padding: '13px 24px',
    minWidth: '106px',
    textAlign: 'center',
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none'
    }
  },
  dateInputGroup: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  dateInputWrapper: {
    position: 'relative'
  },
  dateInputLabel: {
    position: 'absolute',
    bottom: '-14.5px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.grayscale[60]
  },
  inputNoErrorModifier: {
    display: 'none'
  }
}));

export interface DateInputGroupProps {
  setDateValue: (value: Date | null, shouldValidate: boolean) => void;
  validateDate: () => void;
  dateError?: string;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  initialValue?: Date;
  inputWrapperClassName?: string;
  inputClassName?: string;
  noPlaceholders?: boolean;
  className?: string;
}
type DateFormStateType = {
  month: string;
  day: string;
  year: string;
};

const DateInputGroup = ({
  setDateValue,
  validateDate,
  dateError,
  label,
  maxDate,
  minDate,
  initialValue,
  inputWrapperClassName,
  inputClassName,
  noPlaceholders,
  className
}: DateInputGroupProps) => {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);
  const initialStates =
    initialValue && moment(initialValue).isValid()
      ? {
          month: moment(initialValue).format('MM'),
          day: moment(initialValue).format('DD'),
          year: moment(initialValue).format('YYYY')
        }
      : { month: '', day: '', year: '' };

  const { setValue, isDirtyfield, values, validateField, errors, resetErrors } = useForm(initialStates, {
    validate: (values) => {
      const errors: ValidationErrors<DateFormStateType> = {};
      const date = moment(`${values.year}-${values.month}-${values.day}`, 'YYYY-M-D', true);

      if (!values.month || Number(values.month) > 12 || Number(values.month) < 1) errors.month = ' ';
      if (!values.day || Number(values.day) > 31 || Number(values.day) < 1) errors.day = ' ';
      if (!values.year) errors.year = ' ';
      if (values.year && maxDate && values.year < moment(maxDate).format('YYYY')) errors.year = ' ';
      if (date.isValid() && minDate && date.isAfter(moment(minDate))) {
        // eslint-disable-next-line no-return-assign
        ['year', 'month', 'day'].map((key) => (errors[key] = ' '));
      }
      return errors;
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name as any, value, { shouldValidate: isDirty });
  };

  const handleClickOutside = (event: React.FocusEvent<HTMLInputElement>) => {
    const isInsideInputGroup = ['year', 'month', 'day'].includes((event?.relatedTarget as HTMLInputElement)?.name);
    if (isDirty || isInsideInputGroup) return;
    if (isDirtyfield('day') || isDirtyfield('month') || isDirtyfield('year')) {
      setIsDirty(true);
      ['year', 'month', 'day'].map((key) => validateField(key as any, values));
      validateDate();
    }
  };

  // Trigger fields validation on prop error
  useEffect(() => {
    if (dateError) {
      setIsDirty(true);
      ['year', 'month', 'day'].map((key) => validateField(key as any, values));
    } else resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateError]);

  // Trigger prop event on value change
  useEffect(() => {
    const date = moment(`${values.year}-${values.month}-${values.day}`, 'YYYY-M-D', true);
    if (date.isValid()) setDateValue(date.toDate(), isDirty);
    else setDateValue(null, isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <div className={className || classes.dateInputGroup}>
        <div className={classes.dateInputWrapper} style={{ width: '31.5%' }}>
          <Input
            label={label}
            className={inputClassName || classes.dateInput}
            wrapperProps={{ className: inputWrapperClassName }}
            errorClassName={classes.inputNoErrorModifier}
            name='month'
            value={values.month}
            error={errors.month}
            onChange={(e) => handleChange(e)}
            onBlur={handleClickOutside}
            numeric
            maxLength={2}
            noPlaceholders
            maxWidth
          />
          {!noPlaceholders && <div className={classes.dateInputLabel}>MM</div>}
        </div>
        <div className={inputWrapperClassName || classes.dateInputWrapper} style={{ width: '31.5%' }}>
          <Input
            className={inputClassName || classes.dateInput}
            errorClassName={classes.inputNoErrorModifier}
            wrapperProps={{ className: inputWrapperClassName }}
            name='day'
            value={values.day}
            error={errors.day}
            onChange={(e) => handleChange(e)}
            onBlur={handleClickOutside}
            numeric
            maxLength={2}
            noPlaceholders
            maxWidth
          />
          {!noPlaceholders && <div className={classes.dateInputLabel}>DD</div>}
        </div>
        <div className={inputWrapperClassName || classes.dateInputWrapper} style={{ width: '37%' }}>
          <Input
            className={inputClassName || classes.dateInput}
            errorClassName={classes.inputNoErrorModifier}
            wrapperProps={{ className: inputWrapperClassName }}
            name='year'
            value={values.year}
            error={errors.year}
            onChange={(e) => handleChange(e)}
            onBlur={handleClickOutside}
            numeric
            maxLength={4}
            noPlaceholders
            maxWidth
          />
          {!noPlaceholders && <div className={classes.dateInputLabel}>YYYY</div>}
        </div>
      </div>

      {dateError && (
        <Text paragraph className='error-text' style={{ margin: '1rem' }}>
          {dateError}
        </Text>
      )}
    </>
  );
};

export default DateInputGroup;
