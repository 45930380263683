/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme, useTheme } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClinicalNoteIcon,
  ImagingIcon,
  MedicationDispenseIcon,
  DiagnosticReportIcon,
  CommunicationRecordsIcon,
  MedicalConditionIcon,
  ImmunizationIcon,
  PatientIcon,
  PDFIcon,
  AllergyIcon,
  FamilyHistoryIcon,
  GoalIcon,
  ProcedureIcon,
  PatientVisitIcon,
  ObservationIcon,
  MedicationRequestIcon,
  ServiceRequestIcon
} from '../components/shared/Icons';
import {
  GenericHealthInformationResource,
  ResourceActivity,
  ResourceType,
  ServerFile,
  TimelineActivity,
  VersionedText,
  VersionedTextReplacement
} from '../types';
import { FIRST_SIGN_IN_KEY, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from './constants';
import { getRoleFromJWT, isTokenExpired } from './jwt';
import { SupportedLanguage } from '../hooks/useTranslate';
import FileIcon from '../components/shared/FileIcon';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const postalCodeRegex = /^([ABCEGHJ-NPRSTVXY])(\d)([ABCEGHJ-NPRSTV-Z])[ -]?(\d)([ABCEGHJ-NPRSTV-Z])(\d)$/i;
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line @typescript-eslint/naming-convention,quotes
export const __prod__ = process.env.REACT_APP_NODE_ENV === "'production'";

export type Provinces =
  | 'Newfoundland and Labrador'
  | 'Nova Scotia'
  | 'Prince Edward Island'
  | 'New Brunswick'
  | 'Nunavut'
  | 'Québec'
  | 'Ontario'
  | 'Manitoba'
  | 'Saskatchewan'
  | 'Alberta'
  | 'British Columbia'
  | 'Northwest Territories'
  | 'Yukon';

// mostly obtained from https://www.gov.nl.ca/hcs/files/mcp-providers-pim-residents-of-other-provinces.pdf
export const HCN_REGEXES: { [key in Provinces]: RegExp } = {
  Ontario: /(\d{4})-?(\d{3})-?(\d{3})-?([A-Z]{0,2})?/,
  'Prince Edward Island': /(\d{4})-?(\d{4})/,
  Alberta: /(\d{5})-?(\d{4})/,
  'British Columbia': /(\d{4})-?(\d{3})-?(\d{3})/,
  'Nova Scotia': /(\d{4})-?(\d{3})-?(\d{3})/,
  Manitoba: /(\d{3})-?(\d{3})-?(\d{3})/,
  'New Brunswick': /(\d{3})-?(\d{3})-?(\d{3})/,
  Nunavut: /(\d{3})-?(\d{3})-?(\d{3})/,
  Saskatchewan: /(\d{3})-?(\d{3})-?(\d{3})/,
  Yukon: /(\d{3})-?(\d{3})-?(\d{3})/,
  'Newfoundland and Labrador': /(\d{0,12})/,
  'Northwest Territories': /([nN]\d{7})/,
  Québec: /([a-zA-Z]{4})-?(\d{4})-?(\d{4})/
};

export const EXAMPLE_HCNS = {
  Ontario: '1212-121-121-AM',
  'Prince Edward Island': '1212-1212',
  Alberta: '12345-6789',
  'British Columbia': '1212-121-121',
  'Nova Scotia': '1234-567-897',
  Manitoba: '123-123-123',
  'New Brunswick': '123-123-123',
  Nunavut: '123-123-123',
  Saskatchewan: '123-123-123',
  Yukon: '123-123-123',
  'Newfoundland and Labrador': '123456789123',
  'Northwest Territories': 'N1234567',
  Québec: 'ABCD-1234-1234'
};

export const isNumber = (num: any) => num !== undefined && num !== null && !Number.isNaN(num);

export const isValidPhoneNumber = (number: string) => {
  return phoneRegex.test(number);
};

export const getValidNumber = (number: string) => {
  return number.replace(phoneRegex, '($1) $2-$3');
};

export const isValidPostalCode = (code: string) => {
  return postalCodeRegex.test(code);
};

export const getValidPostalCode = (code: string) => {
  return code.replace(postalCodeRegex, '$1$2$3 $4$5$6').toUpperCase();
};

export const isValidEmail = (email: string) => {
  return emailRegex.test(email);
};

export const isValidDate = (value: string | Date) => {
  return moment(value, 'YYYY-MM-DD', true).isValid();
};

export const isValidDOB = (dob: string | Date) => {
  return moment(dob, 'YYYY-MM-DD').isBetween(
    moment().subtract(120, 'years').format('YYYY-MM-DD'),
    moment().subtract(18, 'years').format('YYYY-MM-DD'),
    undefined,
    '[]'
  );
};

export const isValidHCN = (value: string, province: keyof typeof HCN_REGEXES) => {
  if (!value) {
    return false;
  }
  if (!HCN_REGEXES[province]) return true;
  const entireMatchRegex = new RegExp('^' + HCN_REGEXES[province].source + '$');
  return entireMatchRegex.test(value);
};

export const downloadFileFromURL = (fileURL: string, fileName: string) => {
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  saveAs(fileURL, fileName || filename);
};

export const hexToRGB = (hex: string, opacity?: number) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `rgba(${parseInt(result![1], 16)},
  ${parseInt(result![2], 16)},
  ${parseInt(result![3], 16)}
  ${opacity && ',' + opacity})`;
};

export function copy(text: string) {
  const element = document.createElement('textarea');
  element.value = text;
  element.style.all = 'unset';
  element.style.position = 'fixed';
  element.style.clip = 'rect(0, 0, 0, 0)';
  element.style.whiteSpace = 'pre';
  element.style.webkitUserSelect = 'text';
  element.style.userSelect = 'text';
  document.body.appendChild(element);

  element.select();

  document.execCommand('copy');

  if (element) {
    document.body.removeChild(element);
  }
}

export const loggedIn = (): boolean => {
  const token = localStorage.getItem('access_token');
  return !!token && !isTokenExpired(token);
};

export const getToken = () => {
  return localStorage.getItem('access_token');
};

export const tokenExpired = (): boolean => {
  return isTokenExpired(getToken() || undefined);
};

export const getUserEmail = (): string | null => {
  return localStorage.getItem('user_email');
};

export const getRole = (): string | '' => {
  const token = getToken();
  return token ? getRoleFromJWT(token) : '';
};

/**
 * Returns the position where the cursor should be after formatting the date automatically
 * @param {*} newFormattedValue  is the formatted value for the user input
 * @param {*} previousFormattedValue  is the previous valid and formatted value that the user entered
 * @param {*} currentValue is the current value which may/maynot be formatted properly
 * @param {*} currentPosition is the current position of the cursor in the input
 */
export const getCursorPositionForDateFormatting = (
  newFormattedValue: string,
  previousFormattedValue: string,
  currentValue: string,
  currentPosition: number
) => {
  if (!previousFormattedValue || !previousFormattedValue.length) return 1;
  if (currentValue.length < previousFormattedValue.length) {
    if (newFormattedValue.charAt(currentPosition) === '-') {
      return currentPosition;
    }
  }
  if (newFormattedValue.length > currentValue.length) {
    if (currentPosition < 4) return currentPosition;
    return currentPosition + (newFormattedValue.length - currentValue.length);
  }
  if (currentPosition === 4 || currentPosition === 7) {
    return currentPosition + 1;
  }

  return currentPosition;
};

export const truncateStr = (str: string, number: number) => {
  if (str.length <= number) return str;
  return str.slice(0, number) + '...';
};

export const parseBillingCardDescription = (str: string) => {
  return str.split('\n').filter((value) => !!value);
};

export const getProvinceFromPostalCode = (postalCode: string): Provinces | null => {
  if (!postalCode || !postalCode.length) return null;
  let regionName: Provinces | null;

  switch (postalCode[0].toUpperCase()) {
    case 'A':
      regionName = 'Newfoundland and Labrador';
      break;
    case 'B':
      regionName = 'Nova Scotia';
      break;
    case 'C':
      regionName = 'Prince Edward Island';
      break;
    case 'E':
      regionName = 'New Brunswick';
      break;
    case 'G':
    case 'H':
    case 'J':
      regionName = 'Québec';
      break;
    case 'K':
    case 'L':
    case 'M':
    case 'N':
    case 'P':
      regionName = 'Ontario';
      break;
    case 'R':
      regionName = 'Manitoba';
      break;
    case 'S':
      regionName = 'Saskatchewan';
      break;
    case 'T':
      regionName = 'Alberta';
      break;
    case 'V':
      regionName = 'British Columbia';
      break;
    case 'X':
      regionName = 'Northwest Territories';
      break;
    case 'Y':
      regionName = 'Yukon';
      break;
    default:
      regionName = null;
  }

  return regionName;
};

export const mapProvinceToTaxAmount = (province: Provinces) => {
  const FivePercentRegions = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'Northwest Territories',
    'Nunavut',
    'Quebec',
    'Saskatchewan',
    'Yukon'
  ];
  const ThirteenPercentRegions = ['Ontario'];
  const FifteenPercentRegions = ['New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Prince Edward Island'];
  if (FivePercentRegions.includes(province)) return 5;
  if (ThirteenPercentRegions.includes(province)) return 13;
  if (FifteenPercentRegions.includes(province)) return 15;
  return -1;
};

export const convertCentToDollar = (amount: number) => {
  return amount / 100;
};

export const calculateTaxAmountFromProvince = (amount: number, province: Provinces, isStripeAmount = false) => {
  const taxAmount = mapProvinceToTaxAmount(province);
  const subtotal = isStripeAmount ? convertCentToDollar(amount) : amount;
  const returnAmount = ((subtotal * taxAmount) / 100).toFixed(2);
  return parseFloat(returnAmount);
};

export const calculateTaxAmountFromPostalCode = (number: number, postalCode: string, isStripeAmount = false) => {
  const province = getProvinceFromPostalCode(postalCode);
  if (!province) return -1;
  const taxAmount = mapProvinceToTaxAmount(province);
  const amount = isStripeAmount ? convertCentToDollar(number) : number;
  if (taxAmount < 0) return taxAmount;
  return parseFloat(((amount * taxAmount) / 100).toFixed(2));
};

export const calculateTotalAmount = (amount: number, postalCode: string, isStripeAmount = false) => {
  const taxAmount = calculateTaxAmountFromPostalCode(amount, postalCode, isStripeAmount);
  if (taxAmount < 0) return taxAmount;
  const subtotalAmount = isStripeAmount ? amount / 100 : amount;
  const total = parseFloat(taxAmount.toString()) + subtotalAmount;
  return total;
};

export function objParser<T, K extends keyof T>(obj: T, props: K[]): Partial<Pick<T, K>> {
  const newObj: Partial<Pick<T, K>> = {};
  if (!props || !(props.length > 0)) return {};
  props.forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}

export const useFormatDate = () => {
  const { t } = useTranslation('dates');

  const formatDateFn = useCallback(
    (date: moment.MomentInput, format?: DateFormats) => {
      const dateFormat = format ?? 'MMM DD, YYYY';
      //Overriding the defualt nameSpaceSeparator of ":" because some date formats
      // ex (h:mm) would be parsed as mm key inside of h namespace
      const localizedDateFormat = t(`${dateFormat}` as any, { nsSeparator: '|' });

      return moment(date).format(localizedDateFormat);
    },
    [t]
  );

  return formatDateFn;
};

export const useGetInvoiceText = () => {
  const { t } = useTranslation('billingExistingUserScreen');
  const formatDate = useFormatDate();

  return (amount: number, date: moment.MomentInput) => {
    const isPastDate = moment(date).isSameOrBefore(moment.now());
    const formattedDate = formatDate(date, 'MMM DD, YYYY');
    const absAmount = Math.abs(amount);

    if (amount >= 0) {
      if (isPastDate) {
        return t('paymentDescription.charge.past', {
          amount: absAmount.toFixed(2),
          date: formattedDate
        });
      }
      return t('paymentDescription.charge.future', { amount: absAmount.toFixed(2), date: formattedDate });
    }
    if (amount < 0) {
      if (isPastDate)
        return t('paymentDescription.refunds.past', { amount: absAmount.toFixed(2), date: formattedDate });
      return t('paymentDescription.refunds.future', {
        amount: absAmount.toFixed(2),
        date: formattedDate
      });
    }
    return '';
  };
};

export type DateFormats =
  | 'MMM DD, YYYY h:mm A'
  | 'MMM DD, YYYY h:mm a'
  | 'MMMM DD, YYYY h:mm a'
  | 'MMM DD, YYYY'
  | 'MMM DD YYYY'
  | 'MM/YY'
  | 'YYYY-MM-DD'
  | 'YYYY/MM/DD'
  | 'MMM YYYY'
  | 'MM/DD/YYYY'
  | 'MMM DD YYYY [at] h:mm A'
  | 'MMMM DD, YYYY';

export function filter<T>(arr: any[], condition: (item: T) => boolean): T[] {
  return arr.filter((item) => condition(item));
}

export function isEmptyArray(arr: any[] | undefined) {
  return !Array.isArray(arr) || !arr.length;
}

export function isNotEmptyArray<T>(arr: T[] | undefined): arr is T[] {
  return !isEmptyArray(arr);
}

export const generateKey = (...args: any) => {
  if (args) {
    return [...args]
      .filter((item) => !!item)
      .map((item) => {
        if (typeof item === 'object') return JSON.stringify(item);
        if (typeof item === 'function') return '';
        return item?.toString();
      })
      .join('-');
  }
  return (Math.random() * 1e9).toString();
};

export const provinces: Provinces[] = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Québec',
  'Saskatchewan',
  'Yukon'
];

export const isValidProvince = (province: string) => {
  return provinces.includes(province as Provinces);
};

/**
 * @param str The string to check
 * @returns `true` if the param string only contains whitespaces else returns `false`
 */
export const isBlankString = (str: string) => {
  return !str || /^\s*$/.test(str);
};

/**
 * __Note__: Not to be used when security is important
 * @param prefix : Optional prefix for returned id;
 * @returns A random generated string id
 */
export const generateRandomId = (prefix?: string) => {
  const randomValue = Math.random().toString(36).substring(2, 15);
  return prefix ? generateKey(prefix, randomValue) : randomValue;
};

export function isNotUndefined<T>(arg: T | undefined): arg is T {
  return typeof arg !== 'undefined';
}

export const getStyleInformationFromResourceType = (resource_type: string, theme: Theme) => {
  if (resource_type === ResourceType.ResourceGroupResource) {
    return {
      color: theme.palette.primary.main,
      Icon: () => <FileIcon mimeType='directory' />
    };
  }
  if (resource_type === ResourceType.ClinicalNoteResource) {
    return {
      color: theme.palette.forestGreen.main,
      Icon: ClinicalNoteIcon
    };
  }
  if (resource_type === ResourceType.ImagingStudyResource) {
    return {
      color: theme.palette.orange.main,
      Icon: ImagingIcon
    };
  }
  if (resource_type === ResourceType.MedicationRequestResource) {
    return {
      color: theme.palette.shamRock.main,
      Icon: MedicationRequestIcon
    };
  }
  if (resource_type === ResourceType.DiagnosticReportResource) {
    return {
      color: theme.palette.neonBlue.main,
      Icon: DiagnosticReportIcon
    };
  }
  if (resource_type === ResourceType.CommunicationResource) {
    return {
      color: theme.palette.shamRock.main,
      Icon: CommunicationRecordsIcon
    };
  }
  if (resource_type === ResourceType.MedicalConditionResource) {
    return {
      color: theme.palette.mandy.main,
      Icon: MedicalConditionIcon
    };
  }
  if (resource_type === ResourceType.ImmunizationResource) {
    return {
      color: theme.palette.warning.main,
      Icon: ImmunizationIcon
    };
  }
  if (resource_type === ResourceType.FormResource) {
    return {
      color: theme.palette.greenYellow.main,
      Icon: PatientIcon
    };
  }
  if (resource_type === ResourceType.AllergyResource) {
    return {
      color: theme.palette.neonPurple.main,
      Icon: AllergyIcon
    };
  }
  if (resource_type === ResourceType.FamilyMemberHistoryResource) {
    return {
      color: theme.palette.turqBlue.main,
      Icon: FamilyHistoryIcon
    };
  }
  if (resource_type === ResourceType.ProcedureResource) {
    return {
      color: '#A3CA35',
      Icon: ProcedureIcon
    };
  }
  if (resource_type === ResourceType.GoalResource) {
    return {
      color: '#FFDA00',
      Icon: GoalIcon
    };
  }
  if (resource_type === ResourceType.PatientVisitResource) {
    return {
      color: theme.palette.mandy.main,
      Icon: PatientVisitIcon
    };
  }
  if (resource_type === ResourceType.ObservationResource) {
    return {
      color: theme.palette.highlightPink.main,
      Icon: ObservationIcon
    };
  }
  if (resource_type === ResourceType.MedicationDispenseResource) {
    return {
      color: theme.palette.discoBlue.main,
      Icon: MedicationDispenseIcon
    };
  }
  if (resource_type === ResourceType.ServiceRequestResource) {
    return {
      color: theme.palette.primary.hover as string,
      Icon: ServiceRequestIcon
    };
  }

  return {
    color: theme.palette.error.main,
    Icon: PDFIcon
  };
};

export const useGetStyleInformationFromResourceType = (resource_type: string) => {
  const theme = useTheme();
  return getStyleInformationFromResourceType(resource_type, theme);
};

export const isResourceActivity = (activity: TimelineActivity): activity is ResourceActivity => {
  return !!(activity as ResourceActivity).resource;
};

export const deepEqual = (x: any, y: any) => {
  if (x === y) {
    return true;
  }
  if (typeof x === 'object' && x !== null && typeof y === 'object' && y !== null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (const prop in x) {
      if (isNotUndefined(y[prop])) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else {
        return false;
      }
    }

    return true;
  }
  return false;
};

/**
 * Compares the height of a given html string ex. '\<p>Hello world\</p>' and a number to compare it against
 * @param htmlString is the html string (ex `<p>Hello world</p>`) whose height we want to check if attached to the DOM
 * @param maxHeight is the height in px that we want to check the height of the input string against
 * @returns `true` if the height of `htmlString` input is greater than the `maxHeight`,
 * if the `htmlString` were to be rendered on the DOM, `false` if the height of
 * `htmlString` input is less than the `maxHeight`
 */
export const compareHTMLStringHeight = (htmlString: string, maxHeight: number) => {
  const span = document.createElement('span');

  span.innerHTML = htmlString;

  const parent = document.createElement('div');
  parent.style.height = '0px';
  parent.style.maxHeight = '0px';

  parent.append(span);

  document.body.append(parent);

  const height = parent.scrollHeight;

  const isHeightMore = height > maxHeight;

  span.remove();
  parent.remove();
  return isHeightMore;
};

/**
 * Assigns the resource's id to the resource_id property of each file in the resource's files array.
 * Sorts the files in the following order:
 * 1. Previewable files (sorted by file_name)
 * 2. Non-previewable files (sorted by file_name)
 *
 * @param {GenericHealthInformationResource} resource - The resource containing the files to sort.
 * @returns {ServerFile[]} - The sorted files.
 */
export const aggregateAndSortFiles = (resource: GenericHealthInformationResource): ServerFile[] => {
  const { files } = resource;

  if (isNotEmptyArray(files)) {
    files.forEach((file) => {
      file.resource_id = resource.id;
    });

    return files.sort((a, b) => {
      const aCanPreview = canPreviewFileType(a.mime_type);
      const bCanPreview = canPreviewFileType(b.mime_type);

      if (aCanPreview && !bCanPreview) {
        return -1;
        // eslint-disable-next-line no-else-return
      } else if (!aCanPreview && bCanPreview) {
        return 1;
        // eslint-disable-next-line no-else-return
      } else {
        // Sort by file_name if both files are either previewable or non-previewable
        return a.file_name.localeCompare(b.file_name);
      }
    });
  }

  return files as unknown as ServerFile[];
};

export const sortResourcesByFileType = (resources: GenericHealthInformationResource[], fileType: string) => {
  const order = ({ files }: GenericHealthInformationResource) =>
    files?.find((file) => file.mime_type === fileType) ? -1 : 1;

  const sortedResources = resources.sort((a, b) => order(a) - order(b));

  return sortedResources;
};

export const handleFileView = (fileResponse: Blob | string) => {
  const fileURL = URL.createObjectURL(fileResponse);
  window.open(fileURL);
};

export const handleFileDownload = (fileResponse: Blob | string, fileName?: string) => {
  saveAs(fileResponse, fileName);
};

export function isEmptyObject<T extends Record<string | number, unknown>>(object: T): boolean {
  return isEmptyArray(Object.keys(object));
}

export const isStringEmpty = (str: string) => {
  if (!str) return false;
  return str.replace(new RegExp(' ', 'g'), '').replace(new RegExp('\\n', 'g'), '').length === 0;
};

export function isHTMLContentJunk(content: string): boolean {
  const div = document.createElement('div');
  div.innerHTML = content;
  const text = div.innerText;
  const hasContents = isStringEmpty(text);
  div.remove();
  return hasContents;
}

export function conditionalProps<T>(props: T, condition: boolean) {
  if (condition) {
    return props;
  }
}

export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const PREVIEWABLE_FILE_MIME_TYPES = [...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE];

export const canPreviewFileType = (mime_type: string) => PREVIEWABLE_FILE_MIME_TYPES.includes(mime_type);

export const isSameObject = (a: any, b: any) => {
  if (a === b) return true;
  // eslint-disable-next-line
  if (typeof a != 'object' || typeof b != 'object' || a == null || b == null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  // eslint-disable-next-line
  if (keysA.length != keysB.length) {
    return false;
  }

  // eslint-disable-next-line
  for (let key of keysA) {
    if (!keysB.includes(key)) {
      return false;
    }

    if (typeof a[key] === 'function' || typeof b[key] === 'function') {
      // eslint-disable-next-line
      if (a[key].toString() != b[key].toString()) {
        return false;
      }
    } else {
      // eslint-disable-next-line
      if (!isSameObject(a[key], b[key])) {
        return false;
      }
    }
  }

  return true;
};

export const getClassType = (type: string) => {
  return type
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toLocaleLowerCase() + word.slice(1);
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
};

export const addFirstSignInFlag = () => {
  localStorage.setItem(FIRST_SIGN_IN_KEY, 'true');
};

export const removeFirstSignInFlag = () => {
  localStorage.removeItem(FIRST_SIGN_IN_KEY);
};

export const replaceVersionedText = (text: VersionedText, replacements: { [key: string]: string }) => {
  let replacedText = text.display_text;
  if (text.replacements) {
    text.replacements.forEach((replacement) => {
      if (replacement.type !== 'link' && replacements[replacement.key]) {
        replacedText = replacedText.replace(`{{${replacement.key}}}`, replacements[replacement.key]);
      }
    });
  }

  return replacedText;
};

export const getTitleCase = (value: string) => value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

// USED FOR PROFILING REACT COMPONENTS
export const onRenderCallback = (
  id: any, // the "id" prop of the Profiler tree that has just committed
  phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration: any, // time spent rendering the committed update
  baseDuration: any, // estimated time to render the entire subtree without memoization
  startTime: any, // when React began rendering this update
  commitTime: any, // when React committed this update
  interactions: any // the Set of interactions belonging to this update
) => {
  const data = {
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    startTime, // when React began rendering this update
    commitTime, // when React committed this update
    interactions // the Set of interactions belonging to this update
  };
  console.log(id, data);
};

export const GET_INSTITUTION_USERS_LIST_URL = (internalId: string) =>
  `/users/institution/${internalId}?include=latest_record,insurance`;

export const bytesToSizeString = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return;
  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1000 ** i).toFixed(1)} ${sizes[i]}`;
};

/**
 * Finds the specified replacement by key in a versioned text object
 * @param versionedText
 * @param replacementKey
 * @returns
 */
export const getReplacementFromVersionedText = (versionedText: VersionedText, replacementKey: string) => {
  if (versionedText?.replacements) {
    if (versionedText?.replacements?.length > 0) {
      return versionedText.replacements?.find(
        (replacement: VersionedTextReplacement) => replacement?.key === replacementKey
      );
    }
  }
};

// 10 digits support
export const formatPhone = (value: string | undefined) => {
  if (value) {
    const formattedNumber = value.trim().replace(/[^0-9]/g, '');
    if (formattedNumber.length < 4) return formattedNumber;
    if (formattedNumber.length < 7) return formattedNumber.replace(/(\d{3})(\d{1})/, '($1) $2');
    if (formattedNumber.length >= 7) return formattedNumber.slice(0, 10).replace(/(\d{3})(\d{3})(\d{1})/, '($1) $2-$3');
  }
  return value;
};

export const getTimeZoneAbbreviation = (date: Date, language?: SupportedLanguage) => {
  return date
    .toLocaleDateString(language || 'en-CA', {
      day: '2-digit',
      timeZoneName: 'short'
    })
    .slice(4);
};

export const throttle = <T extends any[]>(func: (...args: T) => void, limit: number) => {
  let inThrottle: boolean;
  let lastFunc: NodeJS.Timeout | null = null;
  let lastArgs: T | null = null;

  const throttledFunction = (...args: T) => {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    } else {
      if (lastFunc !== null) {
        clearTimeout(lastFunc);
      }
      lastFunc = setTimeout(() => {
        if (lastArgs !== null) {
          func(...lastArgs);
        }
      }, limit);
      lastArgs = args;
    }
  };

  return throttledFunction;
};
