import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth-context';
import { useRoutes } from '../../../hooks/useRoutes';
import { useTranslate } from '../../../hooks/useTranslate';
import { USER_ACTIONS } from '../../../utils/rbac';
import { isEmptyArray } from '../../../utils/utils';
import { useCheckPermission } from '../../shared/Can';
import GenericErrorScreen from '../../shared/GenericErrorScreen';

import PageContainer from '../../shared/PageContainer';
import Tab from '../../shared/Tab';
import Tabs from '../../shared/Tabs';
import TabsContent from '../../shared/TabsContent';
import TabsList from '../../shared/TabsList';
import Text from '../../shared/Typography';
import AuthorizationController from '../../shared/AuthorizationController';
import { blockStates } from '../../../utils/constants';

// Tab Views
import Overview from './Overview';

import Spinner from '../../shared/Spinner';
import Container from '../../shared/Container';
import { BillingDetailsSkeleton, UsersSkeleton } from '../../Skeletons/SettingsSkeletons';

// Components that are only loaded when tabs are opened
const AccessLogs = lazy(() => import('./AccessLogs'));
const Billing = lazy(() => import('./Billing/index'));
const Users = lazy(() => import('./Users/index'));
const BillingDetails = lazy(() => import('./BillingDetails/index'));

interface Params {
  selectedTab?: string;
}

interface NavItemTab {
  displayName: string;
  pathParam: string;
  redirectPath: string;
}

const useGetSettingsPageParam = () => {
  const { routesTFunction } = useRoutes();

  return useCallback(
    (route: string) => route.replace(routesTFunction('stringToReplaceForRouteParam.setting-tabs'), ''),
    [routesTFunction]
  );
};

const useStyles = makeStyles(() => ({
  card: {
    minHeight: '305px',
    padding: '35px 30px',
    height: '100%'
  },
  section: {
    minHeight: '50vh'
  },
  billingDetails: {
    boxShadow: 'none',
    background: 'none'
  },
  tabListContainer: {
    display: 'flex',
    width: '600px',
    maxWidth: '600px'
  }
}));

const Settings = () => {
  const classes = useStyles();
  const [navItems, setNavItems] = useState<NavItemTab[]>([]);
  const { userInfo, hasPaymentPlan } = useContext(AuthContext);
  const { selectedTab } = useParams<Params>();
  const [view, setView] = useState('');
  const history = useHistory();
  const canViewBillingTab = useCheckPermission(USER_ACTIONS.VIEW.SETTINGS_PAGE_BILLING_TAB);
  const { t, ready } = useTranslate('settingsTabs');
  const { routesTFunction } = useRoutes();
  const getSettingsPageParam = useGetSettingsPageParam();

  useEffect(() => {
    const tabsToAdd: NavItemTab[] = [
      {
        displayName: t('overview'),
        pathParam: getSettingsPageParam(routesTFunction('redirectPaths./settings/overview')),
        redirectPath: routesTFunction('redirectPaths./settings/overview')
      }
    ];
    // Tabs related to institution or institution role
    const userInstitution = userInfo?.institution?.user_institution;
    const userRole = userInstitution?.role;
    if ((userRole === 'owner' || (userInstitution && userInstitution.isBilledSeparately)) && canViewBillingTab) {
      tabsToAdd.push({
        pathParam: getSettingsPageParam(routesTFunction('redirectPaths./settings/billing')),
        displayName: t('billing'),
        redirectPath: routesTFunction('redirectPaths./settings/billing')
      });
    }
    if (userRole && ['owner', 'manager'].includes(userRole)) {
      tabsToAdd.push(
        {
          pathParam: getSettingsPageParam(routesTFunction('redirectPaths./settings/institution')),
          displayName: t('users'),
          redirectPath: routesTFunction('redirectPaths./settings/institution')
        },
        {
          pathParam: getSettingsPageParam(routesTFunction('redirectPaths./settings/security')),
          displayName: t('securityHistory'),
          redirectPath: routesTFunction('redirectPaths./settings/security')
        }
      );
    }
    setNavItems(tabsToAdd);
  }, [userInfo, canViewBillingTab, t, routesTFunction, getSettingsPageParam]);

  useEffect(() => {
    if (!isEmptyArray(navItems)) {
      const activeView = navItems.filter((item) => item.pathParam === selectedTab)[0];
      if (!activeView) {
        setView('error');
      } else {
        setView(activeView.redirectPath);
      }
    }
  }, [navItems, selectedTab]);

  if (isEmptyArray(navItems) || !view) return null;

  if (view === 'error')
    return (
      <PageContainer>
        <GenericErrorScreen />
      </PageContainer>
    );

  if (!ready) {
    return (
      <Container justifyContent='center' height='80vh'>
        <Spinner />
      </Container>
    );
  }

  return (
    <AuthorizationController blockedStates={[blockStates.EMAIL_UNVERIFIED]}>
      <div style={{ margin: '4.5rem 0' }}>
        <Text h3 color='ship-cove'>
          {t('settings')}
        </Text>
      </div>
      <div style={{ padding: '2rem' }}>
        <Tabs
          value={view}
          onValueChange={(value) => {
            history.push(value);
          }}
        >
          <TabsList>
            <div className={classes.tabListContainer}>
              {navItems.map((item) => (
                <Tab value={item.redirectPath} key={item.pathParam} label={item.displayName} />
              ))}
            </div>
          </TabsList>
          <PageContainer className={clsx(view === '/settings/billing' && classes.billingDetails)}>
            <section className={classes.section}>
              <TabsContent value={routesTFunction('redirectPaths./settings/overview')}>
                <Overview />
              </TabsContent>
              <TabsContent value={routesTFunction('redirectPaths./settings/billing')}>
                {hasPaymentPlan ? (
                  <Suspense fallback={<BillingDetailsSkeleton />}>
                    <BillingDetails />
                  </Suspense>
                ) : (
                  <Suspense fallback={<Spinner />}>
                    <Billing />
                  </Suspense>
                )}
              </TabsContent>
              <TabsContent value={routesTFunction('redirectPaths./settings/security')}>
                <Suspense
                  fallback={
                    <div style={{ height: '500px' }}>
                      <Spinner />
                    </div>
                  }
                >
                  <AccessLogs />
                </Suspense>
              </TabsContent>
              <TabsContent value={routesTFunction('redirectPaths./settings/institution')}>
                <Suspense fallback={<UsersSkeleton />}>
                  <Users />
                </Suspense>
              </TabsContent>
            </section>
          </PageContainer>
        </Tabs>
      </div>
    </AuthorizationController>
  );
};

export default Settings;
