import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from '../../hooks/useTranslate';
import Container from './Container';
import Text from './Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '12px',
    padding: '16px 21px',
    background: theme.palette.mandy.main,
    width: '100%'
  }
}));

const InsufficientFundMessage = () => {
  const classes = useStyles();
  const { t, ready } = useTranslate('billing');
  if (!ready) return null;
  return (
    <Container className={classes.container} padding='3rem 0px' margin='2rem 0px 0px' justifyContent='center'>
      <Text paragraph align='center' color='white' icon='warning-circle-white'>
        {t('insufficientFundMessage')}
      </Text>
    </Container>
  );
};

export default InsufficientFundMessage;
