/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface DirectoryFileIconProps extends Omit<SVGBaseProps, 'color'> {}

const DirectoryFileIcon = (props: DirectoryFileIconProps) => {
  return (
    <SVGBase color='#ef1e2b' {...props}>
      <path
        d='M22.6436 19.9365C22.6436 20.467 22.4328 20.9757 22.0578 21.3507C21.6827 21.7258 21.174 21.9365 20.6436 21.9365H4.64355C4.11312 21.9365 3.60441 21.7258 3.22934 21.3507C2.85427 20.9757 2.64355 20.467 2.64355 19.9365V5.93652C2.64355 5.40609 2.85427 4.89738 3.22934 4.52231C3.60441 4.14724 4.11312 3.93652 4.64355 3.93652H9.64355L11.6436 6.93652H20.6436C21.174 6.93652 21.6827 7.14724 22.0578 7.52231C22.4328 7.89738 22.6436 8.40609 22.6436 8.93652V19.9365Z'
        fill='#8285A4'
        stroke='#8285A4'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SVGBase>
  );
};

export default DirectoryFileIcon;
