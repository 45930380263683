import moment from 'moment';
import { useEffect, useLayoutEffect, useState, useCallback, useContext } from 'react';
import { useInfiniteQuery, useQuery as useReactQuery } from 'react-query';
import { SocketContext } from '../../contexts/socket-context';
import useQuery, { GetQueryOptions, useGetQuery, useMutation } from '../../hooks/useQuery';
import apiService from '../../services/api-service';
import { AccessLog, BusinessUpload, InformationRequest, Meta, Notification, ServerFile } from '../../types';
import { SOCKET_EVENT } from '../../utils/constants';
import {
  canPreviewFileType,
  handleFileDownload,
  handleFileView,
  isEmptyArray,
  isNotEmptyArray
} from '../../utils/utils';

export interface UseBusinessUploadRequest {
  file: File[];
  fileField: 'files' | 'export';
  formdata: {
    upload_type: 'patient' | 'record';
  };
}

export const useBusinessUpload = () => {
  const { loading, error, makeRequest, response } = useQuery<UseBusinessUploadRequest>(
    '/business_uploads',
    'FORM_DATA'
  );
  return { isUploading: loading, errorWhileUploading: error, uploadFile: makeRequest, response };
};

export interface UseFetchBusinessUploadStatusResponse {
  business_uploads: BusinessUpload[];
  meta: Meta;
}

export const useFetchBusinessUploadStatus = (options?: GetQueryOptions) => {
  const { response, makeRequest, loading, error } = useGetQuery<UseFetchBusinessUploadStatusResponse>(
    '/business_uploads?status=new,pending&is_batch=1',
    options
  );

  return {
    businessUploadStatus: response,
    isFetchingBusinessUploadStatus: loading,
    errorWhileFetchingBusinessUploadStatus: error,
    fetchBusinessUploadStatus: makeRequest
  };
};

export interface UsePatientRecordUploadRequest {
  file: File[];
  fileField: 'files';
  formdata: {
    upload_type: 'record';
    /**
     * The user id for whom the upload is to be processed
     */
    userId: number;
  };
}

export const usePatientRecordUpload = () => {
  const { loading, error, makeRequest, response } = useQuery<UsePatientRecordUploadRequest>(
    '/business_uploads?is_batch=0',
    'FORM_DATA',
    { fallbackErrorMessage: (t) => t('errorMessages.api.uploadingPatientRecords') }
  );
  return { isUploading: loading, errorWhileUploading: error, uploadFile: makeRequest, uploadResponse: response };
};

export interface UseAvatarUploadRequest {
  file: File;
  fileField: 'image';
  formdata: {
    upload_type: 'avatar';
  };
}

export const useAvatarUpload = () => {
  const { loading, error, makeRequest, response } = useQuery<UseAvatarUploadRequest>('/user/avatar', 'FORM_DATA', {
    fallbackErrorMessage: (t) => t('errorMessages.api.uploadingUserAvatarFile')
  });
  return {
    isUploadingAvatar: loading,
    errorWhileUploadingAvatar: error,
    uploadAvatar: makeRequest,
    uploadAvatarResponse: response
  };
};

export const useFetchAvatar = (userId: number, enabled = false) => {
  const fetchFileThumbnail = async () => {
    try {
      const res = await apiService.file(`/users/${userId}/avatar`, {
        responseType: 'blob'
      });

      return window.URL.createObjectURL(res);
    } catch (error) {
      console.error(error);
    }
  };

  const queryKey = ['userAvatar', userId];

  const { data, isLoading, error, refetch, remove } = useReactQuery(queryKey, fetchFileThumbnail, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: enabled
  });

  return {
    isFetchingAvatar: isLoading,
    errorWhileFetchingAvatar: error,
    avatar: data,
    fetchAvatar: refetch,
    clearAvatarCache: remove
  };
};

export interface UseLogoUploadRequest {
  file: File;
  fileField: 'image';
  formdata: {
    upload_type: 'institution_logo';
  };
}

export const useLogoUpload = () => {
  const { loading, error, makeRequest, response } = useQuery<UseLogoUploadRequest>('/institutions/logo', 'FORM_DATA', {
    fallbackErrorMessage: (t) => t('errorMessages.api.uploadingInstitutionLogoFile')
  });
  return {
    isUploadingLogo: loading,
    errorWhileUploadingLogo: error,
    uploadLogo: makeRequest,
    uploadLogoResponse: response
  };
};

// Authenticated user logo
export const useFetchLogo = () => {
  const { loading, error, makeRequest, response, clearCache } = useGetQuery<BlobPart>(`/institutions/logo`, {
    shouldFetch: false,
    method: 'FILE',
    shouldClearCache: false
  });

  return {
    isFetchingLogo: loading,
    errorWhileFetchingLogo: error,
    logo: response,
    fetchLogo: makeRequest,
    clearLogoCache: clearCache
  };
};

export type UseFetchPatientRecordUploadsResponse = BusinessUpload[];

export const useFetchPatientRecordUploads = (userId: number, options?: GetQueryOptions) => {
  const { loading, error, makeRequest, response } = useGetQuery<UseFetchPatientRecordUploadsResponse>(
    `/users/${userId}/business_uploads?status=new,pending`,
    options
  );

  return {
    isFetchingPatientRecordUploads: loading,
    patientRecordUploads: response,
    errorWhileFetchingPatientRecordUploads: error,
    fetchPatientRecordUploads: makeRequest
  };
};

export interface UseFetchFileThumbnailRequest {
  responseType: 'blob';
}

export type UseFetchFileThumbnailResponse = BlobPart;

export const useFetchFileThumbnail = (fileId: number, mime_type: string) => {
  const fetchFileThumbnail = async () => {
    if (canPreviewFileType(mime_type)) {
      const res = await apiService.get(`/resources/files/${fileId}/thumb`, {
        responseType: 'blob'
      });

      return window.URL.createObjectURL(res);
    }
  };

  const queryKey = ['fileThumbnail', fileId];

  const {
    data: thumbnail,
    isLoading,
    error
  } = useReactQuery(queryKey, fetchFileThumbnail, {
    staleTime: Infinity,
    cacheTime: Infinity
  });

  return {
    thumbnail: thumbnail,
    isFetchingFileThumbnail: isLoading,
    errorWhileFetchingFileThumbnail: error
  };
};

export const useGetFile = (file?: ServerFile) => {
  const { loading, error, makeRequest, response } = useMutation('FILE');

  const getFile = useCallback(
    (file: ServerFile) => {
      return makeRequest(`/resources/${file.resource_id}/files/${file.id}/view`, file).then((res) => {
        return res;
      });
    },
    [makeRequest]
  );

  useEffect(() => {
    if (file) getFile(file);
  }, [getFile, file]);

  return {
    isFetchingFile: loading,
    errorWhileLoadingFile: error,
    file: response,
    getFile
  };
};

export const useViewFile = () => {
  const { loading, error, makeRequest, response } = useMutation('FILE');

  const viewFile = (file: ServerFile) => {
    return makeRequest(`/resources/${file.resource_id}/files/${file.id}/view`, file).then((res) => {
      handleFileView(res);
      return res;
    });
  };

  return {
    loading,
    error,
    file: response,
    viewFile
  };
};

export const useDownloadFile = () => {
  const { loading, error, makeRequest, response } = useMutation<ServerFile>('FILE');

  const downloadFile = (file: ServerFile) => {
    return makeRequest(`/resources/${file.resource_id}/files/${file.id}/view`, file).then((res) => {
      handleFileDownload(res, file.file_name);
      return res;
    });
  };

  const downloadFilesFromResources = (resourceIds: number[], requestId: number, fileName?: string) => {
    if (!requestId) {
      return;
    }

    if (isEmptyArray(resourceIds)) {
      return;
    }

    const resourceIdsEncoded = encodeURIComponent(resourceIds.toString());

    return makeRequest(`/information_requests/${requestId}/resources/files/?resource_ids=${resourceIdsEncoded}`).then(
      (res) => {
        handleFileDownload(
          res,
          res.type === 'application/zip' ? `connect-download-${moment().format('YYYYMMDD')}` : fileName
        );
        return res;
      }
    );
  };

  return {
    loading,
    error,
    file: response,
    downloadFile,
    downloadFilesFromResources
  };
};

export interface UseUpdatePatientInformationRequest {
  user: {
    email?: string;
    first_name?: string;
    dob?: string;
    last_name?: string;
    insurance_number?: string;
    phone?: string;
    gender?: string;
    address?: {
      city?: string;
      province?: string;
      country?: string;
      postal_code?: string;
      street_address?: string;
      extra_line_1?: string;
    };
  };
}

export const useUpdatePatientInformation = (id: number) => {
  const { loading, error, response, makeRequest } = useQuery<UseUpdatePatientInformationRequest>(`/users/${id}`, 'PUT');

  return {
    isUpdatingPatientInformation: loading,
    updatePatientInformation: makeRequest,
    updatedPatientInforamtion: response,
    errorWhileUpdatingPatientInformation: error
  };
};

export const useDownloadBusinessUpload = () => {
  const { loading, error, makeRequest, response } = useMutation<Blob>('FILE');

  const downloadBusinessUpload = (userId: number, businessUpload: BusinessUpload) => {
    return makeRequest(`/users/${userId}/business_uploads/${businessUpload.id}/view`).then((res) => {
      handleFileDownload(res, businessUpload.file_name);
      return res;
    });
  };

  return {
    isDownloadingBusinessUpload: loading,
    errorWhileDownloadingBusinessUpload: error,
    downloadBusinessUpload,
    businessUpload: response
  };
};

export const useViewBusinessUpload = () => {
  const { loading, error, makeRequest, response } = useMutation<Blob>('FILE');

  const viewBusinessUpload = (userId: number, businessUpload: BusinessUpload) => {
    return makeRequest(`/users/${userId}/business_uploads/${businessUpload.id}/view`).then((res) => {
      handleFileView(res);
      return res;
    });
  };

  return {
    isFetchingBusinessUpload: loading,
    errorWhileFetchingBusinessUpload: error,
    viewBusinessUpload,
    businessUpload: response
  };
};

export interface UseFetchSecurityHistoryResponse {
  events: AccessLog[];
  meta: Meta;
}

export const useFetchSecurityHistory = () => {
  const [events, setEvents] = useState<AccessLog[]>();

  const { isLoading, data, fetchNextPage, isFetchingNextPage, error, hasNextPage, status } =
    useInfiniteQuery<UseFetchSecurityHistoryResponse>(
      'security-history',
      ({ pageParam = 0 }) => {
        return apiService.get('/institutions/audit_logs', { params: { page: pageParam } });
      },
      {
        getNextPageParam: ({ meta }, allPageData) => {
          const newPageNumber = allPageData.length;

          if (!meta) {
            return undefined;
          }

          const hasMorePages = meta.total_pages > newPageNumber;

          if (hasMorePages) {
            return newPageNumber;
          }

          return undefined;
        },
        refetchOnWindowFocus: false
      }
    );

  useLayoutEffect(() => {
    if (isNotEmptyArray(data?.pages)) {
      const allEvents: AccessLog[] = [];
      data?.pages.forEach(({ events }) => {
        allEvents.push(...events);
      });
      setEvents(allEvents);
    }
  }, [data]);

  return {
    isLoadingInitial: isLoading,
    isLoading: status === 'loading',
    events,
    fetchNextPage,
    isFetchingNextPage,
    error: error,
    hasNextPage,
    status,
    meta: data?.pages[0].meta
  };
};

interface UseFetchRequestDetailsResponse {
  information_request: InformationRequest;
}

export const useFetchRequestDetails = (requestId: string) => {
  const { response, error, loading, makeRequest } = useGetQuery<UseFetchRequestDetailsResponse>(
    // eslint-disable-next-line
    `/information_requests/institution/${requestId}?include=user,fax_template,status_history,insurance,creator,access_permissions`
  );

  return {
    isFetchingRequestDetails: loading,
    fetchRequestDetails: makeRequest,
    informationRequest: response?.information_request,
    errorWhileFetchingRequestDetails: error
  };
};

export const useDownloadConsentForm = () => {
  const { loading, makeRequest, error, response } = useMutation<never, Blob>('FILE');

  const downloadConsentForm = (informationRequest: InformationRequest) => {
    makeRequest(`/information_requests/${informationRequest.id}/consent_form`).then((blob) => {
      handleFileDownload(blob, `${moment(informationRequest.createdAt).format('YYYYMMDD')}_signed_consent.pdf`);
      return blob;
    });
  };

  return {
    isDownloadingConsentForm: loading,
    errorWhileDownloadingConsentForm: error,
    consentForm: response,
    downloadConsentForm
  };
};
export interface UseFetchInformationRequestDetailsResponse {
  information_request: InformationRequest;
}

export const useFetchInformationRequestDetails = (informationRequestId: number, options?: GetQueryOptions) => {
  const { response, loading, error, makeRequest } = useGetQuery<UseFetchInformationRequestDetailsResponse>(
    `/information_requests/${informationRequestId}`,
    options
  );

  return {
    informationRequestDetails: response,
    isFetchingInformationDetails: loading,
    errorWhileFetchingInformationDetails: error,
    fetchInformationRequestDetails: makeRequest
  };
};

export interface UseFetchNotificationsResponse {
  notifications: Notification[];
  meta: Meta;
}

export const useFetchNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { socket } = useContext(SocketContext);

  const { isLoading, data, fetchNextPage, isFetchingNextPage, error, hasNextPage, status } =
    useInfiniteQuery<UseFetchNotificationsResponse>(
      'notifications',
      ({ pageParam = 0 }) => {
        return apiService.get('/notifications', { params: { page: pageParam } });
      },
      {
        getNextPageParam: ({ meta }, allPageData) => {
          const newPageNumber = allPageData.length;

          if (!meta) {
            return undefined;
          }

          const hasMorePages = meta.total_pages > newPageNumber;

          if (hasMorePages) {
            return newPageNumber;
          }

          return undefined;
        },
        refetchOnWindowFocus: false
      }
    );

  useLayoutEffect(() => {
    if (isNotEmptyArray(data?.pages)) {
      const allNotifications: Notification[] = [];
      data?.pages.forEach(({ notifications }) => {
        allNotifications.push(...notifications);
      });
      setNotifications(allNotifications);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (!socket) return;
      socket.off(SOCKET_EVENT.NEW_NOTIFICATION);
      socket.off(SOCKET_EVENT.READ_NOTIFICATIONS);
      socket.off(SOCKET_EVENT.DELETED_NOTIFICATION);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    // Add new notification
    socket.on(SOCKET_EVENT.NEW_NOTIFICATION, async (data: string) => {
      const { notification } = JSON.parse(data);
      if (!notification) return;
      setNotifications((prevState) => [notification, ...prevState]);
    });
    // Update notifications (mark as read)
    socket.on(SOCKET_EVENT.READ_NOTIFICATIONS, async (data: string) => {
      const { notificationIds } = JSON.parse(data);
      if (!notificationIds || !notificationIds.length) return;
      setNotifications((prevState) => {
        const updatedNotifications = prevState.map((notification) => {
          if (notificationIds.some((notificationId: number) => notificationId === notification.id)) {
            return { ...notification, isRead: true };
          }
          return notification;
        });
        return updatedNotifications;
      });
    });
    // Delete notification
    socket.on(SOCKET_EVENT.DELETED_NOTIFICATION, async (data: string) => {
      const { notification: deletedNotification } = JSON.parse(data);
      if (!deletedNotification) return;
      setNotifications((prevState) => prevState.filter((notification) => notification.id !== deletedNotification.id));
    });
  }, [socket]);

  return {
    isLoadingInitial: isLoading,
    isLoading: status === 'loading',
    notifications,
    fetchNextPage,
    isFetchingNextPage,
    error: error,
    hasNextPage,
    status,
    meta: data?.pages[0].meta
  };
};

export const useFetchSignature = (requestId: number, options?: { enabled: boolean }) => {
  const url = `/information_requests/${requestId}/signature`;
  const { isLoading, error, data } = useReactQuery<BlobPart>(
    url,
    () => {
      return apiService.file(url);
    },
    options
  );

  return {
    isFetchingSignature: isLoading,
    errorWhileFetchingSignature: error,
    signature: data
  };
};
