import React, { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../contexts/auth-context';
import { SocketContext } from '../contexts/socket-context';
import apiService from '../services/api-service';
import { IncomingRequest, SocketInformationRequestResponse } from '../request-hooks/partners/usePartnerRequests';
import { InformationRequest, PartnerClient } from '../types';
import {
  ALL_ACTIVE_META_COUNT_QUERY_KEY,
  ALL_COMPLETE_META_COUNT_QUERY_KEY,
  ALL_REQUEST_LAST_UPDATED_QUERY_KEY,
  ALL_REQUEST_PATIENT_NAME_AZ_QUERY_KEY,
  ALL_REQUEST_PATIENT_NAME_ZA_QUERY_KEY,
  ALL_REQUEST_REQUEST_STATUS_QUERY_KEY,
  CLIENT_LIST_NAME_AZ_QUERY_KEY,
  CLIENT_LIST_NAME_ZA_QUERY_KEY,
  CLIENT_LIST_NEWEST_QUERY_KEY,
  CLIENT_TAB_STATISTICS_QUERY_KEY,
  INCOMING_REQUEST_QUERY_KEY,
  MY_ACTIVE_META_COUNT_QUERY_KEY,
  MY_COMPLETE_META_COUNT_QUERY_KEY,
  MY_REQUEST_LAST_UPDATED_QUERY_KEY,
  MY_REQUEST_PATIENT_NAME_AZ_QUERY_KEY,
  MY_REQUEST_PATIENT_NAME_ZA_QUERY_KEY,
  MY_REQUEST_REQUEST_STATUS_QUERY_KEY,
  SOCKET_EVENT
} from '../utils/constants';
import {
  addIncomingRequestsToCache,
  addNewRequestToCache,
  decrementRequestCountMeta,
  deleteIncomingRequestFromCache,
  incrementRequestCountMeta,
  updateInformationRequestCache
} from '../utils/react-query-helpers/information-requests-helpers';
import { isEmptyArray, isNotEmptyArray, GET_INSTITUTION_USERS_LIST_URL } from '../utils/utils';
import {
  addNewUsersToCache,
  deleteUserInCache,
  incrementUserStatisticsCache,
  updateUserInformationInCache
} from '../utils/react-query-helpers/user-request-helpers';

/**
 *  This HOC component will handle sockets we want to remain open while our app is open
 */
const UpdateCacheWrapper = ({ children }: { children: React.ReactNode }) => {
  const { socket } = useContext(SocketContext);
  const queryClient = useQueryClient();
  const { userInfo } = useContext(AuthContext);

  const updateAllRequestSummaryMetaData = (status: string, oldStatus: string) => {
    if (status === 'Complete') {
      // decrement request meta count for active request in ALL REQUEST Table
      decrementRequestCountMeta(queryClient, ALL_ACTIVE_META_COUNT_QUERY_KEY);
      // increment request meta count for complete request in ALL REQUEST Table
      incrementRequestCountMeta(queryClient, ALL_COMPLETE_META_COUNT_QUERY_KEY);
    }
    if (oldStatus === 'Complete') {
      // decrement request meta count for complete request in ALL REQUEST Table
      decrementRequestCountMeta(queryClient, ALL_COMPLETE_META_COUNT_QUERY_KEY);
      // increment request meta count for active request in ALL REQUEST Table
      incrementRequestCountMeta(queryClient, ALL_ACTIVE_META_COUNT_QUERY_KEY);
    }
  };

  const updateMyRequestSummaryMetaData = (status: string, oldStatus: string) => {
    if (status === 'Complete') {
      // decrement request meta count for active request in ALL REQUEST Table
      decrementRequestCountMeta(queryClient, MY_ACTIVE_META_COUNT_QUERY_KEY);
      // increment request meta count for complete request in ALL REQUEST Table
      incrementRequestCountMeta(queryClient, MY_COMPLETE_META_COUNT_QUERY_KEY);
    }
    if (oldStatus === 'Complete') {
      // decrement request meta count for complete request in ALL REQUEST Table
      decrementRequestCountMeta(queryClient, MY_COMPLETE_META_COUNT_QUERY_KEY);
      // increment request meta count for active request in ALL REQUEST Table
      incrementRequestCountMeta(queryClient, MY_ACTIVE_META_COUNT_QUERY_KEY);
    }
  };

  /**
   *  Refetch Client Queries for other cached data we don't update manually
   */
  const refetchNonUpdatedClientQueries = async () => {
    await queryClient.refetchQueries({ queryKey: CLIENT_LIST_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: CLIENT_LIST_NAME_ZA_QUERY_KEY });
  };

  /**
   *  Refetch Request Queries for other cached data we don't update manually
   */
  const refetchNonUpdatedRequestQueries = async () => {
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_PATIENT_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_PATIENT_NAME_ZA_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_REQUEST_STATUS_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_PATIENT_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_PATIENT_NAME_ZA_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_REQUEST_STATUS_QUERY_KEY });
  };

  const handleNewUsersFromSocket = async (newClients: PartnerClient[]) => {
    refetchNonUpdatedClientQueries();

    try {
      const responses = await Promise.all(
        newClients.map((user: any) =>
          queryClient.fetchQuery(`user-latest-record-${user.token}`, () => {
            return apiService.get(GET_INSTITUTION_USERS_LIST_URL(user.internal_id));
          })
        )
      );

      if (isNotEmptyArray(responses)) {
        const newUsers = responses.map((response: any) => {
          return { ...response.user, is_read: true };
        });

        addNewUsersToCache(queryClient, newUsers);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateClientsEvent = async (data: string) => {
    refetchNonUpdatedClientQueries();

    const update = JSON.parse(data);
    const usersListCache = queryClient.getQueriesData(CLIENT_LIST_NEWEST_QUERY_KEY);

    // If list cache is empty do nothing
    if (isEmptyArray(usersListCache)) return;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { token, internal_id, is_read } = update.user;
    const resp = await queryClient.fetchQuery(`user-latest-record-${token}`, () => {
      return apiService.get(`/users/institution/${internal_id}?include=latest_record,insurance`);
    });
    const updatedUser = resp.user;
    updateUserInformationInCache(queryClient, token, { ...updatedUser, is_read });

    // Refetch Client Queries for other cached data we don't update
    await queryClient.refetchQueries({ queryKey: CLIENT_LIST_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: CLIENT_LIST_NAME_ZA_QUERY_KEY });
  };

  const handleNewClientsEvent = async (data: string) => {
    const { newClients } = JSON.parse(data);
    const usersListCache = queryClient.getQueriesData(CLIENT_LIST_NEWEST_QUERY_KEY);

    // If list cache is empty do nothing
    if (isEmptyArray(usersListCache)) return;

    incrementUserStatisticsCache(queryClient, newClients.length);

    // If there are more than 5 users in 1 time to add in cache we just just invalidate the cache and refetch data
    if (newClients.length > 5) {
      await queryClient.refetchQueries({ queryKey: [CLIENT_LIST_NEWEST_QUERY_KEY] });
    } else {
      handleNewUsersFromSocket(newClients);
    }
  };

  const handleDeletedClientEvent = async (data: string) => {
    refetchNonUpdatedClientQueries();

    const { deleted_client: deletedClient } = JSON.parse(data);
    const usersListCacheNewest = queryClient.getQueriesData(CLIENT_LIST_NEWEST_QUERY_KEY);
    const usersListCacheNameAZ = queryClient.getQueriesData(CLIENT_LIST_NAME_AZ_QUERY_KEY);
    const usersListCacheNameZA = queryClient.getQueriesData(CLIENT_LIST_NAME_ZA_QUERY_KEY);
    const usersStatistics = queryClient.getQueriesData(CLIENT_TAB_STATISTICS_QUERY_KEY);
    // If list cache is empty do nothing
    if (
      isEmptyArray(usersListCacheNewest) &&
      isEmptyArray(usersListCacheNameAZ) &&
      isEmptyArray(usersListCacheNameZA) &&
      isEmptyArray(usersStatistics)
    )
      return;

    deleteUserInCache(queryClient, deletedClient.token);
  };

  const handleUpdateRequestEvent = async (data: string) => {
    refetchNonUpdatedRequestQueries();

    if (!socket) return;

    const myRequestListCache = queryClient.getQueriesData(MY_REQUEST_LAST_UPDATED_QUERY_KEY);
    const allRequestListCache = queryClient.getQueriesData(ALL_REQUEST_LAST_UPDATED_QUERY_KEY);

    // If both list cache are empty do nothing
    if (isEmptyArray(myRequestListCache) && isEmptyArray(allRequestListCache)) {
      return;
    }

    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      request: { token, is_read, last_updated_at, status, accessorUserId, oldStatus }
    } = JSON.parse(data);

    const updatedInformationRequest = {
      token,
      is_read,
      last_updated_at,
      ...(status ? { status } : {})
    } as InformationRequest;

    /* Update the ALL REQUEST LIST cache for updated request if its initialized */
    if (isNotEmptyArray(allRequestListCache)) {
      updateInformationRequestCache(
        queryClient,
        ALL_REQUEST_LAST_UPDATED_QUERY_KEY,
        updatedInformationRequest,
        'LastUpdated'
      );
      updateAllRequestSummaryMetaData(status, oldStatus);
    }

    /* Update the MY REQUEST LIST cache for updated request */
    if (accessorUserId && accessorUserId === userInfo?.id) {
      /* Add the newly fetched request to the MY REQUEST LIST cache if its initialized */
      if (isNotEmptyArray(myRequestListCache)) {
        updateInformationRequestCache(
          queryClient,
          MY_REQUEST_LAST_UPDATED_QUERY_KEY,
          updatedInformationRequest,
          'LastUpdated'
        );
        updateMyRequestSummaryMetaData(status, oldStatus);
      }
    }
  };

  const handleNewRequestsEvent = async (data: string) => {
    refetchNonUpdatedRequestQueries();

    if (!socket) return;

    // eslint-disable-next-line
    const {
      newRequests: { internalIds, creatorId }
    } = JSON.parse(data);

    if (!internalIds || !creatorId || !socket) {
      return;
    }

    // Refetch Request Queries for other cached data we don't update
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_PATIENT_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_PATIENT_NAME_ZA_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: ALL_REQUEST_REQUEST_STATUS_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_PATIENT_NAME_AZ_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_PATIENT_NAME_ZA_QUERY_KEY });
    await queryClient.refetchQueries({ queryKey: MY_REQUEST_REQUEST_STATUS_QUERY_KEY });

    try {
      const myRequestListCache = queryClient.getQueriesData(MY_REQUEST_LAST_UPDATED_QUERY_KEY);
      const allRequestListCache = queryClient.getQueriesData(ALL_REQUEST_LAST_UPDATED_QUERY_KEY);

      // If both list cache are empty just refetch the following queries
      if (isEmptyArray(myRequestListCache) && isEmptyArray(allRequestListCache)) {
        await queryClient.refetchQueries({ queryKey: MY_REQUEST_LAST_UPDATED_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: MY_ACTIVE_META_COUNT_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: MY_COMPLETE_META_COUNT_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: ALL_REQUEST_LAST_UPDATED_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: ALL_COMPLETE_META_COUNT_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: ALL_ACTIVE_META_COUNT_QUERY_KEY });
        return;
      }
      // If there are more than 5 request in 1 time to add in cache we
      // just invalidate the cache and refetch data
      if (internalIds.length > 5) {
        // If new requests are created by the current user we refetch both list
        if (creatorId && creatorId === userInfo?.id) {
          await queryClient.refetchQueries({ queryKey: MY_REQUEST_LAST_UPDATED_QUERY_KEY });
          await queryClient.refetchQueries({ queryKey: MY_ACTIVE_META_COUNT_QUERY_KEY });
          await queryClient.refetchQueries({ queryKey: MY_COMPLETE_META_COUNT_QUERY_KEY });
        }

        // else we just refetch all request list
        await queryClient.refetchQueries({ queryKey: ALL_REQUEST_LAST_UPDATED_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: ALL_COMPLETE_META_COUNT_QUERY_KEY });
        await queryClient.refetchQueries({ queryKey: ALL_ACTIVE_META_COUNT_QUERY_KEY });
        return;
      }

      try {
        // Fetch all request using internalId
        const responses = await Promise.all<SocketInformationRequestResponse>(
          internalIds.map((id: string) => {
            return apiService.get(
              `/information_requests/institution/${id}?include=physician,status_history,institution,user`
            );
          })
        );

        responses.forEach((response) => {
          /* Add the newly fetched request to the ALL REQUEST LIST cache if its initialized */
          if (isNotEmptyArray(allRequestListCache)) {
            addNewRequestToCache(queryClient, ALL_REQUEST_LAST_UPDATED_QUERY_KEY, response.information_request);
          }

          // Increase active request count for ALL REQUEST TABLE
          incrementRequestCountMeta(queryClient, ALL_ACTIVE_META_COUNT_QUERY_KEY, internalIds.length);

          if (creatorId && creatorId === userInfo?.id) {
            /* Add the newly fetched request to the MY REQUEST LIST cache if its initialized */
            if (isNotEmptyArray(myRequestListCache)) {
              addNewRequestToCache(queryClient, MY_REQUEST_LAST_UPDATED_QUERY_KEY, response.information_request);
            }

            // Increase active request count for MY REQUEST TABLE
            incrementRequestCountMeta(queryClient, MY_ACTIVE_META_COUNT_QUERY_KEY, internalIds.length);
          }
          socket.emit(SOCKET_EVENT.JOIN_REQUESTS, {
            requestTokens: [response.information_request.token]
          });

          return response.information_request;
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewIncomingRequest = async (data: string) => {
    const { request_ids: requestIds } = JSON.parse(data);

    if (!socket || !requestIds) return;

    try {
      const incomingRequestCache = queryClient.getQueriesData(INCOMING_REQUEST_QUERY_KEY);
      try {
        // Fetch all incoming request using internalId
        const responses = await Promise.all<IncomingRequest>(
          requestIds.map((id: string) => {
            return apiService.get(`/incoming/information_requests/${id}`);
          })
        );

        if (responses?.length > 0) {
          /* Add the newly fetched incoming requests to the INCOMING REQUEST LIST cache if its initialized */
          if (isNotEmptyArray(incomingRequestCache)) {
            addIncomingRequestsToCache(queryClient, responses);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteIncomingRequest = async (data: string) => {
    const { request_id: requestId } = JSON.parse(data);

    if (!socket || !requestId) return;

    try {
      const incomingRequestCache = queryClient.getQueriesData(INCOMING_REQUEST_QUERY_KEY);
      /* Remove the selected incoming request from the cache list if its initialized */
      if (isNotEmptyArray(incomingRequestCache)) {
        deleteIncomingRequestFromCache(queryClient, requestId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Request Table Sockets
  useEffect(() => {
    if (!socket) return;

    // Turn on Request Sockets
    socket.on(SOCKET_EVENT.NEW_REQUESTS, handleNewRequestsEvent);
    socket.on(SOCKET_EVENT.UPDATE_REQUEST, handleUpdateRequestEvent);

    // Turn off Request sockets when the component unmounts
    return () => {
      // Turn off sockets listening to new request and update request events
      socket.off(SOCKET_EVENT.NEW_REQUESTS);
      socket.off(SOCKET_EVENT.UPDATE_REQUEST);
    };
    // eslint-disable-next-line
  }, [socket, userInfo]);

  // User Table Sockets
  useEffect(() => {
    if (!socket) return;

    // Turn on User Sockets
    socket.on(SOCKET_EVENT.NEW_CLIENTS, handleNewClientsEvent);
    socket.on(SOCKET_EVENT.UPDATE_CLIENT, handleUpdateClientsEvent);
    socket.on(SOCKET_EVENT.DELETED_CLIENT, handleDeletedClientEvent);

    // Turn off User Sockets when the component unmounts
    return () => {
      // Turn off sockets listening to new client, update client, and deleted client events
      socket.off(SOCKET_EVENT.NEW_CLIENTS);
      socket.off(SOCKET_EVENT.UPDATE_CLIENT);
      socket.off(SOCKET_EVENT.DELETED_CLIENT);
    };
    // eslint-disable-next-line
  }, [socket, queryClient]);

  useEffect(() => {
    if (!socket) return;
    socket.on(SOCKET_EVENT.NEW_INCOMING_REQUESTS, handleNewIncomingRequest);
    socket.on(SOCKET_EVENT.REJECT_REQUEST, handleDeleteIncomingRequest);
    socket.on(SOCKET_EVENT.ACCEPT_REQUEST, handleDeleteIncomingRequest);

    return () => {
      if (!socket) return;
      socket.off(SOCKET_EVENT.NEW_INCOMING_REQUESTS, handleNewIncomingRequest);
      socket.off(SOCKET_EVENT.REJECT_REQUEST, handleDeleteIncomingRequest);
      socket.on(SOCKET_EVENT.ACCEPT_REQUEST, handleDeleteIncomingRequest);
    };
    // eslint-disable-next-line
  }, [socket]);
  return <>{children}</>;
};

export default UpdateCacheWrapper;
