/* eslint-disable max-len */
import SVGBase, { SVGBaseProps } from './SVGBase';

export interface PDFIconProps extends Omit<SVGBaseProps, 'color'> {}

const PDFIcon = (props: PDFIconProps) => {
  return (
    <SVGBase color='#ef1e2b' {...props}>
      <path
        d='M17.5 0H2.5C1.125 0 0 1.125 0 2.5V17.5C0 18.875 1.125 20 2.5 20H17.5C18.875 20 20 18.875 20 17.5V2.5C20 1.125 18.875 0 17.5 0ZM6.875 9.375C6.875 10.4125 6.0375 11.25 5 11.25H3.75V12.8125C3.75 13.325 3.325 13.75 2.8125 13.75C2.3 13.75 1.875 13.325 1.875 12.8125V7.5C1.875 6.8125 2.4375 6.25 3.125 6.25H5C6.0375 6.25 6.875 7.0875 6.875 8.125V9.375ZM13.125 11.875C13.125 12.9125 12.2875 13.75 11.25 13.75H8.75C8.4 13.75 8.125 13.475 8.125 13.125V6.875C8.125 6.525 8.4 6.25 8.75 6.25H11.25C12.2875 6.25 13.125 7.0875 13.125 8.125V11.875ZM18.125 7.1875C18.125 7.7 17.7 8.125 17.1875 8.125H16.25V9.375H17.1875C17.7 9.375 18.125 9.8 18.125 10.3125C18.125 10.825 17.7 11.25 17.1875 11.25H16.25V12.8125C16.25 13.325 15.825 13.75 15.3125 13.75C14.8 13.75 14.375 13.325 14.375 12.8125V7.5C14.375 6.8125 14.9375 6.25 15.625 6.25H17.1875C17.7 6.25 18.125 6.675 18.125 7.1875ZM3.75 9.375H5V8.125H3.75V9.375ZM10 11.875H11.25V8.125H10V11.875Z'
        fill='#EF1E2B'
      />
    </SVGBase>
  );
};

export default PDFIcon;
