import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/';

import { SubmitFunction, useForm, ValidationErrors } from '../../hooks/useForm';
import { useCreatePatient } from '../../request-hooks/partners/usePartnerRequests';
import { useTranslate } from '../../hooks/useTranslate';

import Button from './Button';
import Container from './Container';
import Input from './Input';
import Spinner from './Spinner';
import Text from './Typography';
import DropdownInput from './DropdownInput';

import {
  isValidHCN,
  isValidEmail,
  isValidPhoneNumber,
  isNotUndefined,
  deepEqual,
  hexToRGB,
  isValidPostalCode,
  generateKey,
  provinces,
  Provinces,
  EXAMPLE_HCNS,
  getTitleCase,
  formatPhone
} from '../../utils/utils';
import { ClientUpload, PartnerClient } from '../../types';
import { useUpdatePatientInformation } from '../../request-hooks/shared/useSharedRequest';
import { MAX_CLIENT_BIRTH_DATE, MIN_CLIENT_BIRTH_DATE } from '../../utils/constants';
import DateInputGroup from './Input/DateInputGroup';

const provincesForDropDown = ['', ...provinces];

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    margin: '3rem 0px',
    '&>label': {
      [theme.breakpoints.up('tablet')]: {
        width: '80%',
        margin: '1.2rem auto'
      }
    },
    [theme.breakpoints.up('widescreen')]: {
      maxWidth: '782px'
    }
  },
  width100: {
    width: '100%'
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dateInputRow: {
    [theme.breakpoints.down('md')]: {
      gap: '30px',
      flexDirection: 'column'
    }
  },
  inputWrapper: {
    width: '46%'
  },
  addressInputWrapper: {
    width: '50%'
  },
  input: {
    padding: '13px 24px'
  },
  noInputBorder: {
    borderBottom: `2px solid ${theme.palette.grey['80']}`,
    '&:focus': {
      color: theme.palette.text.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      borderRadius: '1.6rem',
      fontWeight: 300,
      borderWidth: '1px'
    },
    '&::placeholder': {
      color: hexToRGB(theme.palette.text.main, 0.3)
    }
  },
  contactRow: {
    marginTop: '24px'
  },
  editModeDisabled: {
    border: 'none'
  }
}));

const AddPatientFormInitialState = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  insurance_number: '',
  dob: '',
  phone_number: '',
  street_address: '',
  city: '',
  province: '',
  postal_code: '',
  extra_line_1: ''
};

interface ClientFormProps {
  onCreate?: (patient?: ClientUpload) => void;
  onSubmitSuccess?: (patient: PartnerClient, hasUserUpdates?: boolean) => void;
  editMode?: boolean;
  patientDetails?: PartnerClient;
  submitText?: string;
  noInputBorder?: boolean;
}

const ClientForm: React.FC<ClientFormProps> = ({
  noInputBorder,
  onCreate,
  onSubmitSuccess,
  editMode,
  patientDetails,
  submitText
}) => {
  const classes = useStyles();
  const { t, ready } = useTranslate('manualCreatePatient');
  const { t: errorsTFunction } = useTranslate('errors');
  const { isLoading, createPatients, errorWhileCreatingPatients, response } = useCreatePatient();

  const { updatePatientInformation, isUpdatingPatientInformation } = useUpdatePatientInformation(
    Number(patientDetails?.id)
  );
  const { t: provinceTFunction, ready: isProvinceTranslationReady } = useTranslate('provinces');

  const mappedProvinces = useMemo(() => {
    if (isProvinceTranslationReady) {
      return provincesForDropDown.map((province) => {
        if (!province) return { label: '', value: '' };
        return {
          value: province,
          label: provinceTFunction(`${province}` as any)
        };
      });
    }
    return [{ label: '', value: '' }];
  }, [provinceTFunction, isProvinceTranslationReady]);

  const onSubmit: SubmitFunction<typeof AddPatientFormInitialState> = useCallback(
    (values: typeof AddPatientFormInitialState) => {
      values.insurance_number = values.insurance_number ? values.insurance_number.toUpperCase() : '';
      values.postal_code = values.postal_code ? values.postal_code.toUpperCase() : '';
      values.first_name = values.first_name ? getTitleCase(values.first_name) : '';
      values.last_name = values.last_name ? getTitleCase(values.last_name) : '';
      values.phone_number = values.phone_number ? values.phone_number.trim().replace(/[^0-9]/g, '') : '';

      if (editMode && patientDetails) {
        const originalUser = {
          user: {
            id: patientDetails?.id,
            internal_id: patientDetails.internal_id,
            email: patientDetails?.email,
            first_name: patientDetails?.first_name,
            dob: patientDetails?.dob,
            last_name: patientDetails?.last_name,
            insurance: patientDetails?.insurance,
            insurance_number: patientDetails?.insurance?.insurance_number,
            phone: patientDetails?.phone,
            address: {
              city: patientDetails?.address?.city,
              province: patientDetails?.address?.province,
              postal_code: patientDetails?.address?.postal_code,
              street_address: patientDetails?.address?.street_address,
              extra_line_1: patientDetails?.address?.extra_line_1 || ''
            }
          }
        };
        const updatedUser = {
          user: {
            id: patientDetails?.id,
            internal_id: patientDetails.internal_id,
            email: values.email,
            first_name: values.first_name,
            dob: values.dob,
            last_name: values.last_name,
            insurance: patientDetails?.insurance,
            insurance_number: values.insurance_number,
            phone: values.phone_number,
            address: {
              city: values.city,
              province: values.province,
              postal_code: values.postal_code,
              street_address: values.street_address,
              extra_line_1: values?.extra_line_1
            }
          }
        };

        if (deepEqual(originalUser, updatedUser)) {
          // eslint-disable-next-line no-lonely-if
          if (isNotUndefined(onSubmitSuccess)) {
            onSubmitSuccess(updatedUser.user as unknown as PartnerClient);
          }
        } else {
          updatePatientInformation(updatedUser).then((res) => {
            if (isNotUndefined(onSubmitSuccess)) {
              onSubmitSuccess(
                {
                  ...res.user,
                  internal_id: patientDetails.internal_id,
                  insurance: {
                    insurance_number: values.insurance_number
                  },
                  address: {
                    city: values.city,
                    province: values.province,
                    postal_code: values.postal_code,
                    street_address: values.street_address,
                    extra_line_1: values?.extra_line_1
                  }
                },
                true
              );
            }
          });
        }
      } else {
        createPatients({ user: values }).then((res) => {
          if (isNotUndefined(onSubmitSuccess)) {
            onSubmitSuccess(
              {
                ...res.user,
                insurance: {
                  insurance_number: values.insurance_number
                },
                address: {
                  city: values.city,
                  province: values.province,
                  postal_code: values.postal_code,
                  street_address: values.street_address,
                  extra_line_1: values?.extra_line_1
                }
              },
              true
            );
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPatients, updatePatientInformation, editMode, patientDetails]
  );

  const { getFieldProps, handleSubmit, setValue, values, validateField, resetErrors, resetForm } = useForm(
    AddPatientFormInitialState,
    {
      validate: (values) => {
        const errors: ValidationErrors<typeof AddPatientFormInitialState> = {};
        if (!values.first_name) {
          errors.first_name = t('errors.invalidFirstName');
        }
        if (!values.last_name) {
          errors.last_name = t('errors.invalidLastName');
        }
        if (!isValidEmail(values.email)) {
          errors.email = t('errors.invalidEmail');
        }
        if (!values.insurance_number || !isValidHCN(values.insurance_number, values.province as Provinces)) {
          if (values.province) {
            errors.insurance_number = t('errors.invalidHCNWithExample', { example: EXAMPLE_HCNS[values.province] });
          } else {
            errors.insurance_number = t('errors.invalidHCN');
          }
        }
        if (!values.dob) {
          errors.dob = t('errors.invalidDOB');
        }
        if (values.dob && moment(values.dob).isAfter(MIN_CLIENT_BIRTH_DATE)) {
          errors.dob = t('errors.invalidDOB_MIN');
        }
        if (values.dob && moment(values.dob).isBefore(MAX_CLIENT_BIRTH_DATE)) {
          errors.dob = t('errors.invalidDOB');
        }
        if (!values.phone_number || !isValidPhoneNumber(values.phone_number)) {
          errors.phone_number = t('errors.invalidPhoneNumber');
        }
        if (!values.street_address) {
          errors.street_address = t('errors.invalidStreetAddress');
        }
        if (!values.postal_code || !isValidPostalCode(values.postal_code)) {
          errors.postal_code = t('errors.invalidPostalCode');
        }
        if (!values.province) {
          errors.province = t('errors.invalidProvince');
        }
        if (!values.city) {
          errors.city = t('errors.invalidCity');
        }
        return errors;
      },
      shouldFocusError: false
    }
  );

  const getSubmitError = () => {
    if (errorWhileCreatingPatients === errorsTFunction('errorCode.US_ALREADY_EXISTS')) {
      return t('errors.clientExist');
    }
    return t('errors.generic');
  };

  useEffect(() => {
    if (response && isNotUndefined(onCreate)) {
      onCreate(response);
    }
  }, [onCreate, response, t]);

  useEffect(() => {
    resetErrors();
    resetForm();
    if (patientDetails && editMode) {
      setValue('id', String(patientDetails?.id));
      setValue('first_name', patientDetails?.first_name);
      setValue('last_name', patientDetails?.last_name);
      setValue('email', patientDetails?.email);
      setValue('insurance_number', patientDetails?.insurance?.insurance_number);
      setValue('dob', patientDetails?.dob || '');
      setValue('phone_number', patientDetails?.phone || '');
      setValue('street_address', patientDetails?.address?.street_address);
      setValue('city', patientDetails?.address?.city);
      setValue('province', patientDetails?.address?.province);
      setValue('postal_code', patientDetails?.address?.postal_code);
      setValue('extra_line_1', patientDetails?.address?.extra_line_1 || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails, editMode]);

  return (
    <div key={generateKey(patientDetails?.id, editMode)}>
      {ready && (patientDetails || !editMode) ? (
        <form
          key={generateKey(patientDetails?.id, editMode)}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <Container isFlex className={`${classes.width100} ${classes.inputRow}`}>
            <div className={`${classes.inputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('first_name')}
                maxWidth
                label={t('inputsLabels.firstName')}
                noPlaceholders
              />
            </div>
            <div className={`${classes.inputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('last_name')}
                maxWidth
                label={t('inputsLabels.lastName')}
                noPlaceholders
              />
            </div>
          </Container>
          <Container isFlex className={`${classes.width100} ${classes.inputRow} ${classes.dateInputRow}`}>
            <div style={{ width: '50%' }}>
              <DateInputGroup
                label={t('inputsLabels.dob')}
                setDateValue={(value: Date | null, shouldValidate: boolean) =>
                  setValue('dob', value ? moment(value).format('YYYY-MM-DD') : '', { shouldValidate })
                }
                validateDate={() => validateField('dob', values)}
                dateError={getFieldProps('dob').error}
                minDate={MIN_CLIENT_BIRTH_DATE}
                maxDate={MAX_CLIENT_BIRTH_DATE}
                initialValue={moment(patientDetails?.dob || '').toDate()}
              />
            </div>
            <div className={`${classes.inputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps<HTMLInputElement>('insurance_number', {
                  onChange: (e) => {
                    e.persist();
                    const { value } = e.target;
                    validateField('insurance_number', { ...values, insurance_number: value });
                  }
                })}
                maxWidth
                label={t('inputsLabels.hcn')}
                noPlaceholders
              />
            </div>
          </Container>
          <Container isFlex className={`${classes.width100} ${classes.inputRow} ${classes.contactRow}`}>
            <div className={`${classes.inputWrapper}`}>
              <Input
                className={clsx(
                  classes.input,
                  noInputBorder && classes.noInputBorder,
                  editMode && classes.editModeDisabled
                )}
                {...getFieldProps('email')}
                maxWidth
                label={t('inputsLabels.email')}
                disabled={editMode}
                noPlaceholders
              />
            </div>
            <div className={`${classes.inputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('phone_number')}
                value={formatPhone(getFieldProps('phone_number').value)}
                format={formatPhone}
                maxWidth
                label={t('inputsLabels.phoneNumber')}
                noPlaceholders
              />
            </div>
          </Container>
          <div style={{ width: '100%' }}>
            <Input
              className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
              {...getFieldProps('street_address')}
              maxWidth
              label={t('inputsLabels.address')}
            />
          </div>
          <Container isFlex className={`${classes.inputRow}`} style={{ width: '80%' }}>
            <div className={`${classes.addressInputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('extra_line_1')}
                maxWidth
                placeholder={t('inputPlaceholders.addressLine2')}
              />
            </div>
            <div className={`${classes.addressInputWrapper}`}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('city')}
                maxWidth
                placeholder={t('inputPlaceholders.city')}
              />
            </div>
          </Container>
          <Container isFlex style={{ width: '80%', display: 'flex' }}>
            <div className={`${classes.addressInputWrapper}`}>
              <DropdownInput
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps<HTMLSelectElement>('province', {
                  onChange: (e) => {
                    e.persist();
                    const { value } = e.target;
                    validateField('insurance_number', { ...values, province: value });
                  }
                })}
                maxWidth
                options={mappedProvinces}
                placeholderText={t('inputPlaceholders.province')}
              />
            </div>
            <div style={{ width: '35%', maxWidth: '210px' }}>
              <Input
                className={clsx(classes.input, noInputBorder && classes.noInputBorder)}
                {...getFieldProps('postal_code')}
                maxWidth
                placeholder={t('inputPlaceholders.postalCode')}
              />
            </div>
          </Container>
          <Container justifyContent='center' padding='1rem 0px' margin='70px 0 0 0'>
            <Button
              type='submit'
              label={submitText || t('addClient')}
              isDisabled={isLoading || isUpdatingPatientInformation}
              isLoading={isLoading || isUpdatingPatientInformation}
              style={{ padding: '9px 45px' }}
            />
          </Container>
          {errorWhileCreatingPatients && !editMode && (
            <Text paragraph bold align='center' color='error' style={{ marginTop: '16px' }}>
              {getSubmitError()}
            </Text>
          )}
        </form>
      ) : (
        <Container justifyContent='center'>
          <Spinner />
        </Container>
      )}
    </div>
  );
};

export default ClientForm;
